(function () {
'use strict';

angular.module('aerosApp').
factory('ToolLibResource', ToolLibResource);

ToolLibResource.$inject = ['$resource', 'CommonService'];
function ToolLibResource ($resource, CommonService) {
  var baseUrl = CommonService.baseUrl + '/organization/:orgId/:toolType/tools';
  return function (params, methods) {
    return $resource(baseUrl + '/:toolId',
      _.defaults(params, {
        orgId     : '@orgId',
        toolType  : '@toolType',
        toolId    : '@toolId'
      }), _.defaultsDeep(methods, {
        query     : {url: baseUrl, isArray: true },
        create    : {url: baseUrl, method: 'POST'},
        update    : {method: 'PUT'}
      }));
  }
}

}());
